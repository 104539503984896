




















































































































































import Vue from 'vue';
import { Component, Prop, Provide, Watch } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Icon from '../base/Icon.vue';
import MainNavItem, { NavItem } from './MainNavItem.vue';

@Component({
    components: { CollapseTransition, Icon }
})
export default class MainNavigation extends Vue {
    @Provide('registerMainNavItem') childRegistration = this.register;
    @Provide('registerMetaNavItem') metaRegistration = this.registerMeta;
    @Provide('registerSiteNavItem') siteRegistration = this.registerSite;
    @Provide('mainNavTag') tagName = this.$vnode.tag;
    @Prop({ default: false }) editMode: boolean;
    @Prop({ default: false }) pelican: boolean;
    @Prop({ required: true }) site: string;
    @Prop({ required: true }) homeLink: string;
    @Prop({ required: true }) homeLinkAlt: string;
    @Prop() langNav: NavItem[];

    items: NavItem[] = [];
    metaItems: NavItem[] = [];
    siteItems: NavItem[] = [];
    overlay: HTMLElement = null;
    isOpen = false;
    showSiteNav = false;
    overlayTimer: NodeJS.Timeout = null;
    activePath = [];
    activeMetaPath = [];
    scrollY = 0;

    mounted() {
        document.addEventListener('scroll', this.onScroll, { passive: false });
    }

    beforeDestroy() {
        document.body.classList.remove('overflow-hidden');
        document.getElementById('app').classList.remove('lg:max-h-full');
        document.getElementById('app').classList.remove('lg:h-full');
        document.getElementById('app').classList.remove('max-h-screen');
        document.getElementById('app').classList.remove('h-screen');
        document.removeEventListener('scroll', this.onScroll);
    }

    private register(item) {
        this.items.push(item);
        item.$on('click', this.onItemClicked);
    }

    private registerMeta(item: NavItem) {
        this.metaItems.push(item);
    }

    private registerSite(item: NavItem) {
        this.siteItems.push(item);
    }

    back() {
        if (this.activePath.length) {
            this.activePath.pop();
        }
        if (this.activeMetaPath.length) {
            this.activeMetaPath.pop();
        }
        this.showSiteNav = false;
    }

    onItemClicked(item: MainNavItem) {
        const index = this.items.findIndex(x => x._uid === item._uid);
        if (index >= 0 && item.level === 1) {
            this.activePath = (this.activePath.length && this.activePath[0] === index) ? [] : [index];
            this.isOpen = !!this.activePath.length;
        }
    }

    toggleMobile() {
        this.isOpen = !this.isOpen;
        this.activePath = [];
        this.activeMetaPath = [];
        this.showSiteNav = false;
    }

    /**
     * Computed
     */
    get activeItem(): NavItem {
        let currentNode: NavItem = { label: '', target: '_self', items: [...this.items], level: 0, link: '', _uid: 0 };
        if (this.activePath.length) {
            this.activePath.forEach(index => {
                currentNode = currentNode.items[index];
            });
        }
        return currentNode;
    }

    get activeMetaItem(): NavItem {
        let currentNode: NavItem = { label: '', target: '_self', items: [...this.metaItems], level: 0, link: '', _uid: 0 };
        if (this.activeMetaPath.length) {
            this.activeMetaPath.forEach(index => {
                currentNode = currentNode.items[index];
            });
        }
        return currentNode;
    }

    get currentSite(): NavItem {
        return this.siteItems.find(x => x.icon === this.site);
    }

    get logoClasses(): string {
        switch (this.$site) {
            case 'fruechteboxexpress':
                return 'h-10';
            case 'pelicanrouge':
                return 'w-12 h-12 lg:h-24 lg:-mt-12 lg:w-28';
            default:
                return 'w-24 h-8 lg:w-28';
        }
    }

    /**
     * Watcher
     */
    @Watch('isOpen')
    onOpenToggled() {
        if (this.isOpen) {
            this.scrollY = window.scrollY;
            this.overlay = document.createElement('div');
            this.overlay.className = 'hidden fixed top-0 left-0 z-50 w-screen h-screen bg-black bg-opacity-20 opacity-0 transition-opacity lg:block';
            this.overlay.addEventListener('click', this.toggleMobile);
            this.$root.$el.prepend(this.overlay);
            clearTimeout(this.overlayTimer);
            this.overlayTimer = setTimeout(() => {
                if (this.overlay) {
                    this.overlay.classList.remove('opacity-0');
                    this.overlay.classList.add('opacity-100');
                }
            }, 200);
        } else if (this.overlay) {
            this.overlay.classList.remove('opacity-100');
            this.overlay.classList.add('opacity-0');
            this.overlay.removeEventListener('click', this.toggleMobile);
            clearTimeout(this.overlayTimer);
            this.overlayTimer = setTimeout(() => {
                this.overlay.remove();
                this.overlay = null;
            }, 500);
        }
        document.body.classList.toggle('overflow-hidden', this.isOpen);
        document.getElementById('app').classList.toggle('lg:max-h-full', this.isOpen);
        document.getElementById('app').classList.toggle('lg:h-full', this.isOpen);
        document.getElementById('app').classList.toggle('max-h-screen', this.isOpen);
        document.getElementById('app').classList.toggle('h-screen', this.isOpen);
        if (!this.isOpen) {
            window.scrollTo(0, this.scrollY);
            this.scrollY = 0;
        }
    }

    private onScroll(event: Event) {
        if (this.editMode) {
            return;
        }

        if (this.isOpen) {
            event.preventDefault();
        }
    }
}

