








import Vue from 'vue';
import { Component, Inject, Prop, Ref } from 'vue-property-decorator';
import Accordion from './base/Accordion.vue';

@Component
export default class AccordionTeaserItem extends Vue {
    @Inject({
        default: () => null
    }) readonly register!: Function;

    @Ref('accordion') accordion: Accordion;
    @Prop() title: string;
    @Prop() image: string;
    @Prop() iconColor: string;

    group = '';

    mounted() {
        this.register(this);
    }

    open() {
        if (!this.accordion.visible) {
            this.accordion.toggle();
        }
    }

    onAccordionToggle(open: boolean) {
        if (open) {
            this.$emit('open', this.image, this.title);
        }
    }
}
